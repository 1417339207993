<template>
    <div>

        <!-- Banner -->
        <app-banner page-name="Store" />
        <!-- End Banner -->

        <!-- Page Content -->
        <div id="main_area">
            <div class="wrapper">
                <main>
                    <div class="breadcrumbs">
                        <span property="itemListElement" typeof="ListItem">
                            <router-link :to="{ name: 'Home' }" tag="a" property="item" typeof="WebPage" title="Go to Home." class="home">
                                <span property="name">Home</span>
                            </router-link>
                            <meta property="position" content="1">
                        </span> &gt;
                        <span property="itemListElement" typeof="ListItem">
                            <router-link :to="{ name: 'Store' }" tag="a" property="item" typeof="WebPage" title="Go to Store." class="post post-page">
                                <span property="name">Store</span>
                            </router-link>
                            <meta property="position" content="2">
                        </span> &gt;
                        <span class="post post-page current-item">{{ item.name }}</span>
                    </div>
                    <div v-if="!loading" id="post-10" class="post-10 page type-page status-publish hentry">
                        <div class="entry-content">

                            <main>
                                <div id="left-side">
                                    <div id="productPhoto">
                                        <img :src="`${apiDomain}${item.image}`" :alt="item.name">
                                        <i class="fa-solid fa-shirt" id="tshirt-icon"></i>
                                    </div>
                                </div>
                                <div id="right-side">
                                    <h1 id="title">{{ item.name }}</h1>

                                    <p v-if="item && item.description">{{ item.description }}</p>

                                    <template v-if="item.hasOptions">
                                        <p style="margin-top: 20px; margin-bottom: 5px;">Size</p>
                                        <div id="sizes">
                                            <div v-for="(option, index) in item.options" :key="`product-option-${index}`" @click="selectOption(option)" :class="{ 'active': option.selected }">{{ option.size }}</div>
                                        </div>
                                    </template>

                                    <div class="product-count">
                                        <label for="size">Quantity</label>
                                        <div class="display-flex" v-if="item.hasOptions && item.options.find(option => option.selected)">
                                            <div class="qtyminus" @click="changeQTY('-')">-</div>
                                            <input type="text" name="quantity" v-model="item.options.find(option => option.selected).qty" readonly class="qty">
                                            <div class="qtyplus" @click="changeQTY('+')">+</div>
                                        </div>
                                        <div class="display-flex" v-else>
                                            <div class="qtyminus" @click="changeQTY('-')">-</div>
                                            <input type="text" name="quantity" v-model="item.qty" readonly class="qty">
                                            <div class="qtyplus" @click="changeQTY('+')">+</div>
                                        </div>
                                    </div>

                                    <div id="buttons">
                                        <span
                                            v-if="
                                                (!item.outOfStock && !item.hasOptions && item.count >= item.qty) ||
                                                (
                                                    item.hasOptions && item.options.find(option => option.selected) &&
                                                    !item.options.find(option => option.selected).outOfStock &&
                                                    item.options.find(option => option.selected).count >= item.options.find(option => option.selected).qty
                                                )
                                            "
                                            id="addCart"
                                            @click="addToCart"
                                        >Add to Cart</span>

                                        <template v-if="item.hasOptions">
                                            <span v-if="item.options.find(option => option.selected)" id="price">
                                                <span v-if="!item.options.find(option => option.selected).outOfStock && item.options.find(option => option.selected).count >= item.options.find(option => option.selected).qty" id="dynamic-price">${{ item.options.find(option => option.selected).price }}</span>
                                                <span v-else id="dynamic-price" class="danger">Out of stuck</span>
                                            </span>
                                        </template>
                                        <template v-else>
                                            <span v-if="item && item.price" id="price">
                                                <span v-if="!item.outOfStock && item.count >= item.qty" id="dynamic-price">${{ item.price }}</span>
                                                <span v-else id="dynamic-price" class="danger">Out of stuck</span>
                                            </span>
                                        </template>

                                    </div>
                                </div>
                            </main>

                        </div>
                    </div>
                </main>
                <div class="clearfix"></div>
            </div>
        </div>

    </div>
</template>

<script>
import { mapMutations, mapGetters } from 'vuex'
import banner from '@/components/layouts/banner'
import { getStoreProductItem } from '@/api/apiCalls'

export default {
    name: 'StorePage',
    props: ['id'],
    data() {
        return {
            item: {},
            loading: true,
        }
    },
    components: {
        appBanner: banner,
    },
    beforeMount () {
        this.getData()
    },
    computed: {
        ...mapGetters('cart', ['items']),
    },
    methods: {
        ...mapMutations({
            setCartItems: `cart/SET_ITEMS`,
        }),
        getData() {
            getStoreProductItem(this.id).then(({ data }) => {
                this.item = {...data, qty: 1, options: data.options.map(option => ({ ...option, qty: 1, selected: false })) }
            }).catch(({ response }) => {
                if (response.status == 404) {
                    this.$router.push({
                        name: 'Store'
                    })
                }
            }).finally(() => {
                this.loading = false
            });
        },
        changeQTY(type) {
            if (this.item.hasOptions) {
                const options = [
                    ...this.item.options.map(item => {
                        if (item.selected) { item.qty = type == '+' ? Number(item.qty) + 1 : (item.qty > 1 ? item.qty - 1 : 1) }

                        return item
                    }),
                ]

                this.item = {...this.item, options}
            } else {
                this.item.qty = type == '+' ? Number(this.item.qty) + 1 : (this.item.qty > 1 ? this.item.qty - 1 : 1)
            }

            this.$forceUpdate()
        },
        addToCart() {
            this.setCartItems([...this.items, {...this.item}])
        },
        selectOption(option) {
            const options = [
                ...this.item.options.map(item => {
                    if (item.price == option.price && item.size == option.size) {
                        item.qty = 1
                        item.selected = true
                    } else {
                        item.qty = 1
                        item.selected = false
                    }

                    return item
                }),
            ]

            this.item = {...this.item, options}
        }
    },
}
</script>

<style>
.display-flex {
	display: flex;
}
.product-count {
	margin-top: 15px;
}
.product-count .qtyminus,
.product-count .qtyplus {
	width: 34px;
    height: 34px;
    background: #212a3e;
    text-align: center;
    font-size: 19px;
    line-height: 36px;
    color: #fff;
    cursor: pointer;
}
.product-count .qtyminus {
	border-radius: 3px 0 0 3px;
}
.product-count .qtyplus {
	border-radius: 0 3px 3px 0;
}
.product-count .qty {
	width: 60px;
	text-align: center;
}
#left-side {
    position: relative;
    box-sizing: border-box;
    width: 40%;
    height: auto;
    background-color:#ECECEC;
    float: left!important;
}
#right-side {
    position: relative;
    width: 60%;
    height: 100%;
    box-sizing: border-box;
    /* padding-top: 15px; */
    /* padding-bottom: 15px; */
    padding-left: 40px;
    float: left!important;
}
#title {
    font-size: 40px;
}
#sizes {
    display: flex;
    gap: 20px;
}
#sizes .active {
    color: #fff!important;
    background: #212a3e!important;
}
#sizes  div {
    border: 2px solid #B2B2B2;
    width: 50px;
    height: 40px;
    padding: 5px;
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.5s;
    cursor: pointer;
}
#sizes + h3 {
    margin: 20px 0px;
    font-size: 16px;
}
#buttons {
    margin: 30px 0px;
    display: flex;
    align-items: center;
    gap: 20px;
    color: #212A3E;
}
#addCart {
    background-color: #212A3E;
    border: 2px solid #212A3E;
    padding: 5px 20px;
    border-radius: 45px;
    color: white;
    transition: 0.5s;
    cursor: pointer;
    text-decoration: none!important;
}
#addCart:hover {
    color:#212A3E;
    background-color:white;
}
#price {
    border: 2px solid #212A3E;
    padding: 8px 20px;
    border-radius: 45px;
    font-weight: 600;
    transition: 0.5s;
    font-size: 20px;
    color: #212A3E;
    text-decoration: none!important;
}

#price .danger {
    color: #ff1919!important;
}
</style>
